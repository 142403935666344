@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;700&display=swap");
element {
  --main-bg-color: #364f5c;
}
html,
body {
  overflow-x: hidden;
  font-family: "Comfortaa", sans-serif;
}

.carousel-item {
  visibility: hidden;
  display: flex !important;
  flex-direction: column;
  align-self: center;
  transition: none;
}

.carousel-inner {
  display: flex;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  visibility: visible;
}

.homeStart {
  position: relative;
  padding: 5px 50px;
}

.homeStart .left-col .slide-inner a {
  display: none;
}

.our-advantages svg,
.benefitsItem svg,
#our-advantages svg {
  width: 64px;
  height: 64px;
}

#return-funds .row.return-funds-inner {
  border-top: 2px solid #007bff;
  padding-top: 15px;
  margin-top: 35px;
}

#return-funds .row.return-funds-inner > div:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
  left: calc(50% - 10px);
  top: -26px;
  border-radius: 50%;
}

#return-funds .row.return-funds-inner {
  border-top: none;
  border-left: 1px solid #fff;
  padding-left: 15px;
  margin-left: 15px;
}

#return-funds .row.return-funds-inner > div:before {
  top: calc(50% - 10px);
  left: -21px;
}

#details {
  background: #0b1132;
  border-radius: 15px;
  border: 1px solid #0b1132;
  color: #fff;
  display: none;
}

#details h3 {
  color: #fff;
}

.slider {
  position: relative;
}

.slider .bg-banner-image {
  position: absolute;
  z-index: -2;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider .carousel {
  height: 100vh;
}
.slider .carousel .carousel-inner {
  height: 100%;
}
.slider .carousel .carousel-inner .carousel-item {
  height: 100%;
}
.slide-img {
  position: absolute;
  z-index: -2;
  top: 0;
  object-fit: cover;
  height: 100%;
}
.slider .carousel .logo_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.slider .carousel .carousel-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: space-around;
  bottom: 5%;
  top: 15%;
}
.slider .carousel .logo_inner {
  width: 60%;
  height: auto;
}

.slider h2 {
  color: #b5c2f2;
  text-transform: uppercase;
  margin: 30px 0;
  font-weight: bold;
  /*border-bottom: 1px solid #ff9800;*/
}
.slider .carousel .carousel-caption p {
  font-size: 1.5rem;
}
.navbarScroll {
  justify-content: flex-end;
}
.navbar-dark .navbar-nav .nav-link {
  color: #ffffffb8;
}
.nav-link.active {
  color: #ff9800 !important;
  font-weight: bold;
}
.nav-link:hover {
  color: #b5c2f2 !important;
}

.slider:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333333b8; /*#0e153ab8;*/
  z-index: -1;
}

.whywe .row {
  justify-content: space-between;
}
.servicesList {
  padding-left: 0;
}
.servicesItem {
  list-style: none;
  display: flex;
  margin-bottom: 10px;
}
.servicesItem img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.benefits {
  padding-top: 40px;
}
.benefits .title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.benefits .benefitsItem,
.benefits .benefitsItem .textBlock {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  align-items: center;
  height: 100%;
}
.benefitsItem .textBlock {
  border: 1px solid #ff9800;
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
  min-height: 255px;
  z-index: 0;
}
.benefitsItemImg {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff9800;
  border-radius: 50%;
  z-index: 1;
  background: #3d3d3d;
  margin-bottom: -20px;
}
.benefitsItem .textBlock h4 {
  margin: 20px 0;
}
.benefitsItem .textBlock p {
  text-align: center;
}
.strategyItem {
  margin-bottom: 20px;
}
.strategyItemTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.strategyItemTitle h5 {
  margin-bottom: 0;
  font-weight: bold;
}
.strategyItemNumb {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9800;
  border: 3px solid #ff9800;
  border-radius: 50%;
  margin-right: 20px;
  font-weight: bold;
  font-size: 1.25rem;
}

.returnFunds__inner {
  display: flex;
  align-items: center;
}
.returnFundsIMG {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.returnFundsIMG img {
  width: 150px;
  height: auto;
}
.whyWeList {
  padding-left: 0;
}
.whyWeItem {
  list-style: none;
  display: flex;
  align-items: center;
}

.whyWeItem .text {
  border-left: 2px solid #ff9800;
}
.whyWeItem .text h5 {
  font-weight: bold;
  text-transform: none;
}

.whyWeItem .whyWeItemImg {
  margin-right: 20px;
}
.whyWeItem .whyWeItemImg svg {
  width: 40px;
  height: 40px;
}

.ourGoalsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.ourGoalsItem {
  padding: 20px;
  border: 1px solid #ff9800;
  position: relative;
}
.ourGoalsItem .topImage {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  left: calc(50% - 15px);
  background: #3d3d3d;
  border: none;
  border-radius: 50%;
}
.ourGoalsItem .topImage img {
  width: 30px;
}
.ourGoalsItem-1 {
  grid-column-start: 1;
  grid-row-start: 1;

  border-radius: 10px 10px 0px 10px;
}
.ourGoalsItem-2 {
  grid-column-start: 3;
  grid-row-start: 1;
  border-radius: 10px 10px 10px 0px;
}
.ourGoalsItem-3 {
  grid-column-start: 2;
  grid-row-start: 2;
  border-radius: 0px 0px 10px 10px;
}
.homeStart .right form {
  z-index: 50;
}

.homeStart .right .btn-primary {
  width: 100%;
}

.homeStart .right input {
  border-radius: 5px;
}

.PhoneInput {
  border-radius: 5px;
  display: flex !important;
}

.testimonial-content p {
  border: 1px solid #ccc;
  position: relative;
  left: -20px;
  bottom: -10px;
  font-size: 16px;
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: linear-gradient(
    45deg,
    #0000,
    #0000,
    #0000,
    #fff,
    #0000,
    #0000,
    #0000
  );
  border-right: none;
  border-top: none;
}

.about-img {
  width: 250px;
  position: absolute;
  top: -85px;
  right: 0;
  z-index: -1;
}

.PhoneInputInput {
  outline: none;
  border: none;
}

.form-group {
  position: relative;
}

.imgCheckPhone {
  width: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

h3 {
  font-size: 26px;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: bold;
}

h4 {
  font-size: 19px;
  color: #b5c2f2; /*#353b8b;*/
  font-weight: bold;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
}

.btn-primary {
  background: #ff9800;
}

.btn-primary,
.btn-light {
  border-radius: 5px;
  padding: 10px 35px;
  background-color: #ff9800;
  font-weight: bold;
  color: #fff;
  min-width: 300px;
}

.homeStart .left-col p {
  font-size: 24px;
  color: #e0ecff;
}

#map-section iframe {
  width: 100%;
}

.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #000;
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 15px;
  background-position-x: 7.5px;
}

#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

footer .navbar {
  justify-content: space-between;
}
footer .logoImg {
  width: 100%;
  height: auto;
}

footer .copyright {
  text-align: center;
  width: 100%;
  background: #35508b;
  color: #ffffff80;
  padding: 10px;
}

.bg-dark {
  background: #000000c2 !important
}

section.bg-dark {
  color: #fff;
}

section.bg-dark h3 {
  color: #b5c2f2;
}

.modalOverlay {
  background-color: rgba(51, 51, 51, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /*   .modalOverlay__inner {
    width: 60%;
  } */
}
.modalContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 7px;
  box-shadow: 0 0 32px rgba(51, 51, 51, 0.4);
  padding: 40px;
  width: 450px;
  font-size: 26px;
  z-index: 999;
  position: relative;
}
.closeForm {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: auto;
}
.closeForm:hover {
  cursor: pointer;
}
.contactForm {
  padding: 20px 0;
}
.modalContainer .contactForm {
  display: flex;
  flex-wrap: wrap;
}
.modalContainer .contactForm .form-group {
  width: 100%;
}
.modalContainer .contactForm .cF-button {
  width: 100%;
}
.formTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formTitle h2 {
  text-transform: uppercase;
  font-weight: bold;
}
.formTitle p {
  text-align: center;
  color: #ff9800;
  font-size: 1.1rem;
}
.cF-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.form-label {
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }

  /*   .slide-inner .user-avatar > * {
    width: 100% !important;
  } */

  .testimonial-content p:after {
    bottom: 50%;
  }

  .benefitsItem {
    margin-bottom: 20px;
  }
  .benefitsItem:last-child {
    margin-bottom: 0;
  }
  .benefitsItemImg {
    margin-bottom: -40px;
  }
}
@media (min-width: 1025px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1024px) {
  .bg-dark {
    background: #3d3d3d !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: inline-block;
  }
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    flex-basis: auto;
  }
  .collapse.show {
    display: flex !important;
    align-items: flex-start;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .slider .navbar-nav {
    flex-direction: column;
  }
  .slider .carousel-control-next {
    right: 0;
  }
  .slider .carousel-control-prev {
    left: 0;
  }
  footer .navbar-nav {
    flex-wrap: wrap;
    height: 100%;
  }
  footer .navbar-nav > a {
    width: 50%;
    padding-top: 0;
  }
}
@media screen and (max-width: 992px) {
  .ourGoalsList {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .ourGoalsItem-1 {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .ourGoalsItem-2 {
    grid-column-start: 2;
    grid-row-start: 2;
    border-radius: 0px 10px 10px 0;
  }
  .ourGoalsItem-3 {
    grid-column-start: 1;
    grid-row-start: 3;
    border-radius: 10px 0px 10px 10px;
  }
  .testimonial-content p:after {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .slider svg {
    width: 200px;
  }

  .slider h2 {
    font-size: 1.5rem;
  }

  .homeStart .left-col .slide-inner a {
    display: block;
  }

  .slider .carousel {
    height: 60vh;
  }

  .homeStart {
    padding: 50px 15px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .homeStart .left-col p {
    font-size: 16px;
  }

  .our-strategy .col-md-4 {
    order: -1;
  }

  .our-strategy .col-md-4 img,
  #our-advantages img {
    max-height: 250px;
    margin-bottom: 35px;
  }

  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }

  footer .navbar {
    padding: 20px 10px;
  }

  footer .navbar-nav {
    flex-wrap: wrap;
    padding-left: 35px;
    margin-top: 20px;
  }

  footer .navbar-nav > a {
    width: 50%;
  }
}
@media screen and (min-width: 641px) and (max-width: 767px) {
  .our-advantages {
    margin-top: 30px;
  }
}
@media screen and (max-width: 640px) {
  .ourGoalsList {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 40px;
  }
  .ourGoalsItem {
    border-radius: 10px;
  }
  .ourGoalsItem-1 {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .ourGoalsItem-2 {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .ourGoalsItem-3 {
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .benefitsItem .textBlock {
    min-height: auto;
    height: auto;
  }
  .benefitsItem .textBlock h4 {
    margin-top: 30px;
  }
  .our-advantages {
    display: none;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .user-avatar img {
    width: 100px;
    height: auto;
  }
  .slider .carousel {
    height: 60vh;
  }
  .slider-descr {
    display: none;
  }
  .carousel-control-prev {
    left: 0px;
  }

  .carousel-control-next {
    right: 0px;
  }
  .contactForm {
    padding: 5px 0;
  }
  .modalContainer {
    padding: 40px 10px;
  }
  .cF-button {
    margin-top: 20px;
  }
  .formTitle h2 {
    font-size: 1.2rem;
  }
  .formTitle p {
    font-size: 1.1rem;
  }
  .closeForm {
    top: 20px;
  }
  .aboutus-form {
    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) {
  .slider .carousel .logo_inner {
    width: 80%;
  }
  .slider h2 {
    font-size: 1.2rem;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .btn-primary {
    width: 95vw;
    max-width: 300px;
    min-width: 0;
  }

  #testimonials h5 {
    font-size: 11px;
  }

  footer .navbar-nav > a {
    width: 100%;
  }
  .whywe .btn-primary,
  .our-services .btn-primary {
    margin-left: -15px;
  }

  .whyWeItem {
    flex-wrap: wrap;
    width: 100%;
  }
  .whyWeItem .whyWeItemImg,
  .whyWeItem .text {
    margin: 0;
    width: 100%;
  }
  .whyWeItem .whyWeItemImg {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .whyWeItem .text {
    border-left: none;
    border-bottom: 1px solid #ff9800;
    padding: 0 !important;
  }
  .whyWeItem:last-child .text {
    border: none;
  }

  .returnFunds__inner {
    flex-wrap: wrap;
  }
  .returnFundsIMG {
    width: 100%;
    justify-content: center;
    margin-right: 0;
  }
  .strategyItemTitle {
    justify-content: space-between;
  }
  .strategyItemTitle h5 {
    width: 80%;
  }
  .modalContainer .contactForm .form-group {
    width: 100%;
  }
  .modalContainer .contactForm .form-group:first-child {
    padding-right: 0;
  }
  .modalContainer .contactForm .form-group:nth-child(2) {
    padding-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .btn-primary {
    width: 290px;
  }
  .aboutUs .btn-primary {
    width: auto;
  }
}

@media screen and (max-height: 450px) {
  .slider .carousel {
    height: 100vh;
  }
  .slider-descr {
    display: none;
  }
  .carousel-control-prev {
    left: 0px;
  }

  .carousel-control-next {
    right: 0px;
  }
  .navbar-collapse {
    height: 100vh;
  }
  .navbar-nav {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .navbar-nav a {
    width: 50%;
  }
  .contactForm {
    padding: 5px 0;
  }
  .modalContainer {
    padding: 40px 10px;
  }

  .cF-button {
    margin-top: 20px;
  }
  .formTitle h2 {
    font-size: 1.2rem;
  }
  .formTitle p {
    font-size: 1.1rem;
  }
  .closeForm {
    top: 20px;
  }
}
